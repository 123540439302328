import { backgroundFlexibleProp, backgroundTypeProp, mediaQueryProp } from 'utils/proptypes/modules/mediaProps';
import { headingSizeProp, headingTagProp, wysiwygProp } from 'utils/proptypes/modules/textProps';

import AnimationWrapper from 'components/wrappers/AnimationWrapper';
import AspectWrapper from 'components/wrappers/AspectWrapper';
import BackgroundSwitch from 'components/background/BackgroundSwitch';
import ButtonsList from 'components/buttons/ButtonsList';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'components/text/Tag';
import { TextLinkWysisyg } from 'components/text/TextLink';
import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import { above } from 'utils/mediaqueries';
import { buttonsProp } from 'utils/proptypes/modules/buttonsProps';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    position: relative;
    height: 100%;
`;

const Content = styled('div')`
    display: flex;
    justify-content: space-between;

    ${above.sm} {
        margin: 0 -6px;
    }

    ${above.md} {
        margin: 0 -16px;
    }
`;

const HeadingAndTag = styled('div')`
    ${above.sm} {
        padding: 0 6px;
    }

    ${above.md} {
        padding: 0 16px;
    }
`;

const TextAndButtons = styled('div', { shouldForwardProp: prop => ['isColumn'].indexOf(prop) === -1 })`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0 0;

    ${above.sm} {
        padding: 40px 6px 0;
    }
    ${above.md} {
        ${({ isColumn }) =>
            isColumn
                ? `
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0;
        `
                : `
            padding: 0 16px;
        `}
    }
`;

/**
 *  contentBlock used to display heading, text, buttons and background.
 *
 * @param {object} [background] - Background object.
 * @param {string} backgroundType - Type of background if not supplied inside the background object.
 * @param {bool} [basic=false] - Switch between basic and regular layout.
 * @param {string} buttons - Buttons as array.
 * @param {string} heading - Heading as a string.
 * @param {string} headingSize - The size of heading passed as props to Heading.
 * @param {string} headingTag - The html-tag used by heading; h1, h2, h3 etc.
 * @param {bool} [isColumn=false] - Switch between column and regular layout.
 * @param {array} mediaQuery - Array of media-queries used by background image.
 * @param {string} tag - Small tag displayed above heading.
 * @param {array} [text] - Text as a wysiwyg-array.
 * @param {string} textColor - Color of all texts.
 */

const CallToAction = ({
    background,
    backgroundRatio,
    backgroundType,
    basic,
    buttons,
    heading,
    headingSize,
    headingTag,
    isColumn,
    mediaQuery,
    tag,
    text,
    textColor,
}) => (
    <Wrapper color={textColor}>
        {background && (
            <AspectWrapper height="auto !important" marginBottom={['16px', null, '24px']} ratio={backgroundRatio}>
                <BackgroundSwitch type={backgroundType} background={background} mediaQuery={mediaQuery} />
            </AspectWrapper>
        )}
        <Content flexDirection={isColumn ? 'column' : ['column', null, null, 'row']}>
            <HeadingAndTag width={basic ? '100%' : ['100%', null, null, '50%']}>
                <Heading withAnimation as={headingTag} margin="0" size={headingSize}>
                    {heading}
                </Heading>
                <Tag withAnimation marginTop={['16px', null, null, '24px']}>
                    {tag}
                </Tag>
            </HeadingAndTag>
            <TextAndButtons
                alignItems={basic && !isColumn ? ['flex-start', null, null, 'flex-end'] : 'flex-start'}
                isColumn={isColumn}
                width={basic ? '100%' : ['100%', null, null, '50%']}
                transitionDelay="2500ms"
            >
                {text && (
                    <AnimationWrapper>
                        <Wysiwyg
                            data={text}
                            textComponent={props => (
                                <Paragraph marginBottom={basic ? null : '24px'} size="md" {...props} />
                            )}
                            tagComponents={{ url: TextLinkWysisyg }}
                        />
                    </AnimationWrapper>
                )}
                <ButtonsList withAnimation buttons={buttons} />
            </TextAndButtons>
        </Content>
    </Wrapper>
);

CallToAction.propTypes = {
    background: backgroundFlexibleProp,
    backgroundRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    backgroundType: backgroundTypeProp.isRequired,
    basic: PropTypes.bool,
    buttons: buttonsProp.isRequired,
    heading: PropTypes.string.isRequired,
    headingSize: headingSizeProp.isRequired,
    headingTag: headingTagProp.isRequired,
    isColumn: PropTypes.bool,
    mediaQuery: mediaQueryProp.isRequired,
    tag: PropTypes.string.isRequired,
    text: wysiwygProp,
    textColor: PropTypes.string.isRequired,
};

export default CallToAction;
